import { EditorReadyFn, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import * as publicApi from './editor/services/public-api';

let editorSDK: FlowEditorSDK;
let appDefId: string;

const editorReady: EditorReadyFn = async (_editorSDK, appDefinitionId) => {
  editorSDK = _editorSDK;
  appDefId = appDefinitionId;
};

const _exports = {
  refreshApp: () => publicApi.refreshApp(editorSDK),
  setBooleanStyleParamForWidgets: (key: string, value: boolean) =>
    publicApi.setBooleanStyleParamForWidgets(editorSDK, appDefId, key, value),
};

export { editorReady, _exports as exports };
